import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import OtpInput from 'react-otp-input';
import qrcode from 'qrcode';
import generatePayload from 'promptpay-qr';
import moment from 'moment';
import packageJson from '../../../package.json';
import { sign } from 'jsonwebtoken';
import { QRCodeToDataURLOptions } from 'qrcode';
import { setAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { MixpanelTracking } from '../../utility';
import { toast, ToastContainer } from 'react-toastify';
import AddressModal from '../../components/address-modal/AddressModal';

import './PayMobileGuest.css';
import lineIcon from '../../images/other/line.svg'
import loading from '../../images/loading/loading_spinner.svg';
import msgIcon from '../../images/facebook/msg-icon.svg';
import phone from '../../images/other/phone.svg';
import promptPay from '../../images/bank/PromptPay2.png';
import userBlue from '../../images/user/user-blue.svg';
import uploadIcon from '../../images/icon/upload-file-white.svg';

interface Product { price: number; }



const PayMobileGuest = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);

    const [inputName, setInputName] = useState('');
    const [inputPhone, setInputPhone] = useState('');
    const [isOtpButtonLocked, setIsOtpButtonLocked] = useState(false);
    const [isPremium, setIsPremium] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpCountdown, setOtpCountdown] = useState(0);
    const [otpRequested, setOtpRequested] = useState(false);
    const [sendSubmit, setSendSubmit] = useState(false);
    const [showMessenger, setShowMessenger] = useState(false);

    const [address, setAddress] = useState<string>('');
    const [addressAppend, setAddressAppend] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProductExpanded, setIsProductExpanded] = useState(false);
    const [parentPhone, setParentPhone] = useState<string>('');

    const [fetchedAccessToken, setFetchedAccessToken] = useState<string>('');
    const [fetchedAccountId, setFetchedAccountId] = useState<string>('');
    const [fetchedAccount, setFetchedAccount] = useState<any>(null);

    const [amount, setAmount] = useState<number>(0);
    const [isPremiumPlus, setIsPremiumPlus] = useState(false);
    const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string>('');

    const [cutoffDate, setCutoffDate] = useState<moment.Moment>(moment(''));
    const [plusProductList, setPlusProductList] = useState<Array<any>>([]);

    const addressMaxLength = 200;
    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const appVersion = `${packageJson.name} ${packageJson.version}`;
    const currentDate = moment();
    const sortedProductList = productList.slice().sort((a, b) => b.product_id - a.product_id);
    const currentProductList = isPremiumPlus ? plusProductList : sortedProductList;

    const displayedProducts = isProductExpanded
        ? currentProductList
        : isPremiumPlus
            ? currentProductList.slice(0, 3)
            : currentProductList.slice(2, 5);
    const indexToSelect = isPremiumPlus ? 0 : 2;

    const jwtSecret = process.env.REACT_APP_JWT_SECRET;
    if (!jwtSecret) throw new Error("JWT_SECRET is not defined in environment variables");

    const generateToken = (phoneNumber: string, subject: string) => {
        return sign({}, Buffer.from(jwtSecret), {
            issuer: 'webnaschool', algorithm: 'HS256', noTimestamp: true, jwtid: phoneNumber, subject: subject
        });
    };
    const userToken = generateToken(inputPhone, inputPhone);
    const userTokenOTP = generateToken(inputPhone, otp);
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };



    const requestOtp = async () => {

        if (isOtpButtonLocked || !inputPhone) return;
        setIsOtpButtonLocked(true);
        startOtpCountdown();

        const checkApiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/check/phone`;
        const otpApiUrl = `${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`;
        const checkHeaders = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken, };
        const otpHeaders = { 'Content-Type': 'application/x-www-form-urlencoded', };

        try {
            const checkRes = await fetch(checkApiUrl, { method: 'POST', body: JSON.stringify({ phone: inputPhone }), headers: checkHeaders });

            if (checkRes.ok) {
                const checkData = await checkRes.json();
                setIsPremium(checkData.error === 0 && checkData.data === true);
            } else {
                throw new Error('Failed to check phone premium status');
            }

            const otpBody = `access_token=${userToken}&phoneNumber=${inputPhone}&action=requestOTP`;
            const res = await fetch(otpApiUrl, { method: 'POST', body: otpBody, headers: otpHeaders, });

            if (res.ok) {
                toast.success('ส่งรหัส OTP ไปแล้ว!', { position: toast.POSITION.TOP_CENTER });
                setOtpRequested(true);
            } else {
                toast.error('เกิดข้อผิดพลาดขณะทำการส่งรหัส กรุณาลองใหม่อีกครั้ง', { position: toast.POSITION.TOP_CENTER });
            }
            return await res.json();

        } catch (error) {
            toast.error('เกิดข้อผิดพลาดขณะทำการส่งรหัส กรุณาลองใหม่อีกครั้ง', { position: toast.POSITION.TOP_CENTER });
            return error;
        }
    };

    const verifyOtp = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v3/method/smsSignUp`;
        const body = new URLSearchParams({ access_token: userTokenOTP, phoneNumber: inputPhone, action: 'verifyOTP', });
        const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        try {
            const res = await fetch(apiUrl, { method: 'POST', body: body.toString(), headers, });
            return await res.json();
        } catch (e) {
            console.error("Error verifying OTP:", e);
            return e;
        }
    };

    const trySignInWithPhoneNumberAndOTP = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v7/method/account.signIn`;
        const formData = new URLSearchParams({ access_token: userTokenOTP, phoneNumber: inputPhone, appversion: appVersion, clientId: '2016' });
        const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });
        try {
            const res = await fetch(apiUrl, { method: 'POST', body: formData, headers, });
            return await res.json();
        } catch (e) {
            console.error("Error during sign-in:", e);
            return e;
        }
    };

    const signUpWithPhoneNumber = async (param: any) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/v7/method/account.signUp`;
        const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
        const body = new URLSearchParams({
            access_token: userTokenOTP, phoneNumber: inputPhone, appversion: appVersion, clientId: '2016', fullname: param.fullname, username: param.username,
            province: "10", country: "TH", language: "TH", grade: "E5", parent_email: "", email: "", sex: "1"
        });
        try {
            const res = await fetch(apiUrl, { method: 'POST', body: body.toString(), headers });
            return await res.json();
        } catch (e) {
            console.error("Error during signup:", e);
            return e;
        }
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (otp.length !== 4) { toast('กรุณากรอกรหัส OTP'); return; };
        if (isPremiumPlus && address.length > addressMaxLength) { toast('กรุณากรอก ที่อยู่ ไม่เกิน 200 ตัวอักษร'); return; }
        if (!isPremium && !selectedFile) { toast('กรุณาอัปโหลดภาพสลิปโอนเงิน'); return; };
        setSendSubmit(true);

        try {
            const verifyRes = await verifyOtp();
            if (verifyRes.error) {
                toast.error(verifyRes.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });
                return;
            }
            if (selectedFile) {
                const { upload_url, file_id } = await getSlackFileUploadUrl(selectedFile.name, selectedFile.size);
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('uploadUrl', upload_url);
                await uploadFileToServer(formData);
                await completeSlackUpload(file_id, inputName, isPremiumPlus);
            }
            await handleSignInOrSignUp();
            setShowMessenger(true);

        } catch (error) {
            console.error("Error during verification/sign-in:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");

        } finally {
            setSendSubmit(false);
        }
    };

    const handleSignInOrSignUp = async () => {
        try {
            const signInRes = await trySignInWithPhoneNumberAndOTP();

            if (signInRes.error_code === 404) {
                await handleSignUp();

            } else if (signInRes.error_code === 0) {
                MixpanelTracking.identify(signInRes.accountId);
                MixpanelTracking.signIn();
                dispatch(resetLessonSet());
                dispatch(setAccessData({ requestLogin: false, accessToken: signInRes.accessToken, accountId: signInRes.accountId, account: signInRes.account[0] }));

                if (!isPremium) {
                    await createPrePromptPay(signInRes.accountId, userToken);
                    await updateAddress(signInRes.accountId);
                    await updatePhoneNumber(signInRes.accountId);
                    if (isPremiumPlus) await insertPendingSchoolRoom(signInRes.accountId);
                } else {
                    history.push('/payment-history');
                }

            } else if (signInRes.error) {
                toast.error(signInRes.error_description, { position: "top-center", autoClose: 5000, hideProgressBar: false });

            } else {
                toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
            }
        } catch (error) {
            console.error("Error during sign-in/sign-up:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        }
    };

    const handleSignUp = async () => {
        try {
            const [firstName] = inputName.split(' ');
            const { accountId, account, accessToken, error_code, error_description } = await signUpWithPhoneNumber({
                fullname: inputName, username: firstName
            });

            setFetchedAccessToken(accessToken);
            setFetchedAccountId(accountId);
            setFetchedAccount(account);

            if (error_code === 0) {
                MixpanelTracking.alias(accountId);
                MixpanelTracking.identify(accountId);
                MixpanelTracking.signUp('Phone', account[0]);
                ReactGA.event({ category: 'Account', action: 'Sign Up' });
                dispatch(resetLessonSet());
                await createPrePromptPay(accountId, userToken);
                await updateAddress(accountId);
                await updatePhoneNumber(accountId);
                if (isPremiumPlus) await insertPendingSchoolRoom(accountId);

            } else {
                toast(`ไม่สามารถสมัครได้เนื่องจาก : ${error_description} กรุณาติดต่อ Admin`);
            }
        } catch (error) {
            console.error("Sign-up error:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        }
    };

    const createPrePromptPay = async (userId: string, token: string) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/create/pre/promptpay/amount`;
        const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': token, };
        const body = JSON.stringify({ userId, productId: selectedProductId, amount, isPremiumPlus });

        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
        } catch (error) {
            console.error("Error creating pre-prompt pay:", error);
            throw error;
        }
    };

    const startOtpCountdown = () => {
        setOtpCountdown(15);
        const countdownInterval = setInterval(() => { setOtpCountdown((prevTime) => prevTime - 1) }, 1000);
        setTimeout(() => {
            setIsOtpButtonLocked(false);
            clearInterval(countdownInterval);
            setOtpCountdown(0);
        }, 15000);
    };

    const changePlan = async (selectPlan: string) => {
        const currentProductList = isPremiumPlus ? plusProductList : sortedProductList;
        const product = currentProductList.find((t) => t.product_id === selectPlan);

        let baseAmount = parseInt(product.price);
        console.log("I don't know why. But putting a log here fixes this.")

        if (isPremiumPlus) {
            const lifetimeMonths = product.lifetime_of_product || 0;
            const futureDate = currentDate.clone().add(lifetimeMonths, 'months');
            const totalDays = lifetimeMonths * 30;
            const dailyRate = Math.floor(product.price / totalDays);

            if (futureDate.isAfter(cutoffDate)) {
                const daysOver = futureDate.diff(cutoffDate, 'days');
                const deduction = daysOver * dailyRate;
                baseAmount -= deduction;
            }
        }

        dispatch(reloadTransaction(at, aid));
        setAmount(baseAmount);
        setSelectedProductId(selectPlan);
    };

    const handleMessenger = () => {
        dispatch(setAccessData({ requestLogin: true, accessToken: fetchedAccessToken, accountId: fetchedAccountId, account: fetchedAccount[0] }));
        history.push('/profile/edit');
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const maxSizeBytes = 5 * 1024 * 1024; // 5MB

        if (!file.type.startsWith('image/')) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
        } else if (file.size > maxSizeBytes) {
            toast('ขนาดไฟล์ใหญ่เกิน 5MB กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่านี้');
        } else {
            setSelectedFile(file);
            const fileNameDisplay = document.getElementById("payMobileFileName");
            if (fileNameDisplay) fileNameDisplay.textContent = file.name;
            return;
        }
        event.target.value = '';
        setSelectedFile(null);
    };

    const getSlackFileUploadUrl = async (filename: string, length: number) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/getUploadURLExternal`;
        const body = JSON.stringify({ filename, length });
        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const uploadFileToServer = async (formData: FormData) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/uploadExternal`;
        const headers: HeadersInit = { 'token': userToken };
        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body: formData });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const completeSlackUpload = async (file_id: string, name: string, isPremiumPlus: boolean) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/completeUploadExternal`;
        const body = JSON.stringify({ file_id, name, isPremiumPlus });
        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const fetchPremiumPlus = async () => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/payment/product/premium-plus`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(response.statusText);
            const result = await response.json();
            const sortedProducts = result.data.sort((a: Product, b: Product) => b.price - a.price);
            setPlusProductList(sortedProducts);
        } catch (error) {
            console.error("Error fetching premium plus products.");
        }
    };

    const generateQrCode = async () => {
        const mobileNumber = '0205557003965';
        const payload = generatePayload(mobileNumber, { amount: amount });
        const options: QRCodeToDataURLOptions = { color: { dark: '#003b6a', light: '#ffffff' } };
        try {
            const qrCodeDataURL = await qrcode.toDataURL(payload, options);
            setQrCodeDataURL(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const fetchAdminData = async (
        endpoint: string, setter: (value: any) => void, defaultValue: any, processor: (value: any) => any = (value) => value
    ) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/payment/admin/${endpoint}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(response.statusText);
            const result = await response.json();
            const value = result?.data?.[0]?.value ?? defaultValue;
            setter(processor(value));
        } catch (error) {
            console.error(`Error fetching ${endpoint}:`, error);
        }
    };

    const updateAddress = async (userId: string) => {
        if (!address) return;
        const fullAddress = `${address}, ${addressAppend}`;
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/update/address`;
        const body = JSON.stringify({ userId, address: fullAddress });
        try {
            const response = await fetch(url, { method: 'PUT', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error updating address:', error);
        }
    };

    const handleSelectAddress = (address: string) => setAddressAppend(address);

    const updatePhoneNumber = async (userId: string) => {
        if (!parentPhone) return;
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/update/parent-phone`;
        const body = JSON.stringify({ userId, parentPhone });
        try {
            const response = await fetch(url, { method: 'PUT', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error updating parent phone:', error);
        }
    };

    const insertPendingSchoolRoom = async (userId: string) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/school-room/insert/pending`;
        const body = JSON.stringify({ userId });
        try {
            const response = await fetch(url, { method: 'POST', headers, body, });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error inserting pending school room:', error);
            throw error;
        }
    };



    useEffect(() => {
        dispatch(reloadTransaction(at, aid));
        const params = new URLSearchParams(location.search);
        if (params.has('premiumPlus')) setIsPremiumPlus(true);
        fetchAdminData('cutoff-date', setCutoffDate, currentDate, (value) => moment(value));
        fetchPremiumPlus();
    }, [otpRequested]);

    useEffect(() => { if (accessData.accessToken) history.push('/payment-history') }, [accessData]);

    useEffect(() => { generateQrCode() }, [amount]);

    useEffect(() => {
        if (sortedProductList.length > 0 && plusProductList.length > 0) {
            const firstProduct = isPremiumPlus ? plusProductList[0] : sortedProductList[2];
            if (firstProduct) changePlan(firstProduct.product_id);
        }
    }, [isPremiumPlus, plusProductList]);

    useEffect(() => {
        if (productList.length > 0 && !selectedProductId) {
            setSelectedProductId(sortedProductList[indexToSelect].product_id);
            setAmount(parseInt(sortedProductList[indexToSelect].price));
        }
    }, [productList]);



    if (otpRequested && (productList.length === 0 || plusProductList.length === 0 || !selectedProductId || !amount)) {
        return (
            <div className="mobile-pay-loading-container">
                <div className="mobile-pay-spinner"></div>
                <p>กำลังโหลด...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">

                    <div className="mobile-pay-header col-12 pt-4"><h3>สมัครใช้บริการ</h3></div>

                    <div className="col-12 mobile-pay-input-signup">
                        <img src={userBlue} alt="User Icon" />
                        <input placeholder="ชื่อ" value={inputName}
                            onChange={(e) => setInputName(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="col-12 mobile-pay-input-signup">
                        <img src={phone} alt="Phone Icon" />
                        <input placeholder="เบอร์โทรศัพท์" value={inputPhone}
                            onChange={(e) => setInputPhone(e.target.value)}
                            required
                        >
                        </input>
                    </div>

                    <div className="otp-request-button-container col-12">
                        <p className="login-text">กรุณากดปุ่ม "ขอรหัส"</p>
                        <p className="login-text">เพื่อ NockAcademy จะได้ส่งรหัส OTP ไปทาง SMS ของคุณ</p>
                        <p className="login-text">หากคุณยังไม่ได้รับรหัสนี้ กรุณาลองกด "ขอรหัส" ใหม่อีกครั้ง</p>
                        <button
                            id="otp-request-button"
                            onClick={() => requestOtp()}
                            className="btn btn-primary"
                            disabled={isOtpButtonLocked}
                        >
                            {isOtpButtonLocked ? (
                                <span>กดส่งได้อีกครั้งใน: {otpCountdown} วินาที</span>
                            ) : (
                                <span>ขอรหัส</span>
                            )}
                        </button>
                    </div>

                    {otpRequested && (
                        <div className="mobile-pay-otp-container col-12">
                            <div className='row'>
                                <div className="col-10 col-sm-8 otp-container">
                                    <OtpInput value={otp} onChange={(e: string) => setOtp(e)} numInputs={4} inputStyle={"otp-input"} separator={<span></span>} isInputNum={true} />
                                </div>
                            </div>
                        </div>
                    )}

                    {otpRequested && !isPremium && (
                        <div className="mobile-pay-step-2 col-12">
                            <div className="mobile-pay-premium-text-payment col-12">
                                <div>เลือกคอร์สเรียน</div>

                                <div className="mobile-pay-button-group">
                                    <div
                                        className={`mobile-pay-category-button ${!isPremiumPlus ? 'active' : ''}`}
                                        onClick={() => setIsPremiumPlus(false)}
                                    >
                                        Premium
                                    </div>
                                    <div
                                        className={`mobile-pay-category-button ${isPremiumPlus ? 'active' : ''}`}
                                        onClick={() => setIsPremiumPlus(true)}
                                    >
                                        Premium Plus
                                    </div>
                                </div>

                                <div className="mobile-pay-product-selection">
                                    {displayedProducts.map((product) => {
                                        const basePrice = parseFloat(product.price);
                                        const monthlyPrice = basePrice / product.subscription_months;

                                        const lastProduct = currentProductList[currentProductList.length - 1];
                                        const lastBasePrice = parseFloat(lastProduct.price);
                                        const lastMonthlyPrice = lastBasePrice / lastProduct.subscription_months;

                                        const savingsPercentage = lastMonthlyPrice > 0
                                            ? Math.ceil((1 - (monthlyPrice / lastMonthlyPrice)) * 100)
                                            : 0;
                                        const trimmedDescription = product.description_th.replace(/ Premium Plus$/, '');

                                        return (
                                            <div
                                                key={product.product_id}
                                                className={`mobile-pay-product-item ${selectedProductId === product.product_id ? 'selected' : ''}`}
                                                onClick={() => changePlan(product.product_id)}
                                            >
                                                <div className="mobile-pay-product-name">
                                                    <label className="mobile-pay-product-radio">
                                                        <input
                                                            type="radio" name="product" value={product.product_id}
                                                            checked={selectedProductId === product.product_id} readOnly
                                                        />
                                                        {trimmedDescription}
                                                    </label>
                                                    {Number(savingsPercentage) > 0 && (
                                                        <div className="mobile-pay-savings-percentage">
                                                            ประหยัด {Number(savingsPercentage)}%
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="mobile-pay-product-pricing">
                                                    <div className="mobile-pay-monthly-price">
                                                        ฿ {Math.ceil(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })} ต่อเดือน
                                                    </div>
                                                    <div className="mobile-pay-total-price">
                                                        ทั้งหมด ฿ {basePrice.toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {currentProductList.length > 3 && (
                                        <div onClick={() => setIsProductExpanded(!isProductExpanded)} className="mobile-pay-view-all-button">
                                            {isProductExpanded ? 'ปิด' : 'ดูทั้งหมด'}
                                        </div>
                                    )}
                                </div>

                                <div className="mobile-pay-checkbox-list">
                                    <ul>
                                        {[
                                            { id: "1", text: "ไลฟ์สอนสดฟรี" },
                                            { id: "2", text: "ไลฟ์สอนสดพรีเมียม" },
                                            { id: "3", text: "ชมคลิปบทเรียนสั้น" },
                                            { id: "4", text: "ชมคลิปติวสอบเข้าโรงเรียนดัง" },
                                            { id: "5", text: <><span>รายงานผลการเรียน</span><br /><span>พร้อมคำแนะนำ</span></>, premiumOnly: true },
                                            { id: "6", text: "หนังสือประกอบการเรียน", premiumOnly: true },
                                        ].map(({ id, text, premiumOnly }) => (
                                            <li key={id}>
                                                {premiumOnly ? (
                                                    isPremiumPlus ? (
                                                        <>
                                                            <input type="checkbox" id={`mobile-pay-checkbox-${id}`} checked readOnly />
                                                            <label htmlFor={`mobile-pay-checkbox-${id}`}>{text}</label>
                                                        </>
                                                    ) : (
                                                        <label htmlFor={`mobile-pay-checkbox-${id}`}>
                                                            <span className="mobile-pay-empty-checkbox">—</span> {text}
                                                        </label>
                                                    )
                                                ) : (
                                                    <>
                                                        <input type="checkbox" id={`mobile-pay-checkbox-${id}`} checked readOnly />
                                                        <label htmlFor={`mobile-pay-checkbox-${id}`}>{text}</label>
                                                    </>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            </div>

                            <div className="mobile-pay-price-display col-12">
                                ยอดที่ต้องชำระ – {amount.toLocaleString()} บาท
                            </div>

                            {selectedProductId && (
                                <div className="mobile-pay-qr col-12">
                                    <img id="promptpay-logo" src={promptPay} alt="PromptPay Logo" />
                                    {qrCodeDataURL && <img src={qrCodeDataURL} alt={`QR Code for ${amount} baht`} />}
                                    <p>หมายเลขอ้างอิง: 0-2055-57003-96-5</p>
                                </div>
                            )}

                            {isPremiumPlus && (
                                <div className="mobile-pay-address col-12 mt-4">
                                    <input
                                        type="text" id="addressAppend" value={addressAppend} readOnly required
                                        onClick={() => setIsModalOpen(true)} className="form-control"
                                        placeholder="เลือกจังหวัด อำเภอ ตำบล รหัสไปรษณีย์..."
                                    />
                                    <input
                                        type="text" id="address" value={address} maxLength={addressMaxLength} required
                                        onChange={(e) => setAddress(e.target.value)} className="form-control"
                                        placeholder="กรอกที่อยู่ เลขที่บ้าน อาคาร ซอย..."
                                    />
                                    <input
                                        type="text" id="parentPhone" value={parentPhone} maxLength={10} required
                                        onChange={(e) => setParentPhone(e.target.value)} className="form-control"
                                        placeholder="กรอกเบอร์โทร สำหรับติดต่อเพิ่มเติม..."
                                    />
                                    <AddressModal
                                        isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSelect={handleSelectAddress}
                                    />
                                </div>
                            )}

                            <div className="mobile-pay-notes col-12">
                                <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                                <ul className="mobile-pay-notes-list">
                                    <li>Capture หน้าจอโทรศัพท์</li>
                                    <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                    <li>อัปโหลดสลิปหลักฐานการโอนเงินผ่านปุ่มด้านล่าง</li>
                                    <li>ยอมรับเงื่อนไขและข้อตกลง แล้วกดสมัครใช้บริการ</li>
                                    <li>แจ้งการชำระเงินกับแอดมินผ่านทาง Messenger</li>
                                </ul>
                            </div>

                            <div className="pay-mobile-image-upload col-12">
                                <input type="file" id="fileUpload" onChange={handleFileChange} />
                                <label htmlFor="fileUpload">
                                    อัปโหลดสลิป
                                    <img src={uploadIcon} alt="Upload" className="pay-mobile-upload-icon" />
                                </label>
                                <span id="payMobileFileName"></span>
                            </div>

                            <div className="mobile-pay-requirements col-12 mt-4 d-flex align-items-center">
                                <p>ข้าพเจ้ายอมรับ</p>
                                <p>
                                    <label>
                                        <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>
                                        และ
                                        <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a>
                                    </label>
                                    <span style={{ marginLeft: "10px" }}></span>
                                    <input className="mobile-pay-accept mr-2" type="checkbox" id="accept-terms" required />
                                </p>
                            </div>
                        </div>
                    )}

                    {otpRequested && !showMessenger && (
                        <div className="col-12 mobile-pay-register-button-container">
                            <button id="mobile-pay-register-button" className="m-auto" type="submit" disabled={sendSubmit}>
                                {isPremium ? 'เข้าสู่ระบบ' : 'สมัครใช้บริการ'}
                                <img style={{ maxHeight: "8vh" }} src={loading} hidden={!sendSubmit} />
                            </button>
                        </div>
                    )}

                    {otpRequested && showMessenger && !isPremium && !isPremiumPlus && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://m.me/NockAcademyElementary?text=ขอแจ้งโอนเงินสำหรับผู้ใช้งาน%20–%20${inputName}`}
                                className="pay-mobile-message-us-button col-12"
                                target="_blank"
                                onClick={handleMessenger}
                            >
                                <img src={msgIcon} alt="Message Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                    {otpRequested && showMessenger && !isPremium && isPremiumPlus && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://line.me/R/ti/p/%40nockacademye456`}
                                className="pay-mobile-line-us-button col-12"
                                target="_blank"
                                onClick={handleMessenger}
                            >
                                <img src={lineIcon} alt="LINE Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </form >
    );
};

export default PayMobileGuest;
