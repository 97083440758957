import React from 'react';
import Latex from 'react-latex';
import { ImageUtility } from '../../utility';
import closeImg from '../../images/actions/close.svg';

interface LiveQuizAnswerProps {
    accessData: NaTypes.Authenticate;
    choice: string | undefined;
    liveQuizPercentage: NaTypes.LiveQuizPercentage;
    question: LiveQuestion;
    sendingSubmit: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    showAnswer: boolean;
    submitAnswer: (questionId: string, answerId: string) => void;
}



const LiveQuizAnswer: React.FC<LiveQuizAnswerProps> = ({
    accessData, choice, liveQuizPercentage, question, sendingSubmit, setShow, showAnswer, submitAnswer
}) => {



    const getChoiceClass = (isChosen: boolean, isCorrectAnswer: boolean, showAnswer: boolean): string => {
        if (showAnswer) {
            return isChosen
                ? isCorrectAnswer ? 'choice-default correct' : 'choice-default incorrect'
                : isCorrectAnswer ? 'choice-default choice' : 'choice-default';
        }
        return isChosen ? 'choice-default choice' : 'choice-default';
    };

    const getChoiceBackground = (isChosen: boolean, isCorrectAnswer: boolean, percent: number) => {
        return `linear-gradient(90deg, 
            ${isChosen ? (isCorrectAnswer ? '#19b71f' : '#c7c7cc') : '#c7c7cc'} ${percent}%, 
            ${isChosen ? (isCorrectAnswer ? '#c4f7c6' : '#f0f0f5') : '#f0f0f5'} 0%)`;
    };

    const renderChoice = (answer: LiveQuestionAnswer, i: number): JSX.Element => {
        const isChosen = choice === answer.id;
        const isCorrectAnswer = answer.isCorrect === '1';
        const choiceClass = getChoiceClass(isChosen, isCorrectAnswer, showAnswer);

        return <div className={choiceClass}>{i + 1}</div>;
    };

    const renderAnswerPercentage = (i: number) => {
        const answer = liveQuizPercentage.percentage?.answers[i];
        if (!answer) return null;

        const { isCorrect, percent } = answer;
        const isCorrectAnswer = isCorrect === true;
        const isChosen = choice === answer.id;
        const background = getChoiceBackground(isChosen, isCorrectAnswer, percent);

        return (
            <div style={{ display: showAnswer ? 'block' : 'none' }}>
                <div className="answer-percentage-container" style={{ background, borderRadius: '200px' }}></div>
                <p className="answer-percentage-text" style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    {Math.floor(percent ?? 0)}%
                </p>
            </div>
        );
    };

    const renderImageChoice = (a: LiveQuestionAnswer, i: number) => {
        const isCorrectAnswer = a.isCorrect === '1';
        const isChosen = choice === a.id;
        const answerPercent = liveQuizPercentage.percentage?.answers[i]?.percent ?? 0;
        const background = getChoiceBackground(isChosen, isCorrectAnswer, answerPercent);
        const choiceClass = getChoiceClass(isChosen, isCorrectAnswer, showAnswer);

        return (
            <div className="image-choice-layout">
                <div style={{ display: showAnswer ? 'block' : 'none' }}>
                    <div className="answer-percentage-container" style={{ background, borderRadius: '14px', height: '25%' }}></div>
                    <p className="answer-percentage-text">{Math.floor(answerPercent)}%</p>
                </div>

                <div className="answer-choice-container">
                    <div className={choiceClass}>{i + 1}</div>
                    <p className="m-0"><Latex>{a.value != null ? a.value : ''}</Latex></p>
                </div>

                {a.imagePath && <img className="image-choice w-100" src={a.imagePath} alt="Image Choice" />}
            </div>
        );
    };



    return (
        <div className="col-6 h-100 p-2" id="answer-container">

            <img className="quiz-close can-click" id="answer-side-close" src={closeImg} alt="Close Button" onClick={() => setShow(false)} />

            {question && (
                <div
                    className={`row m-0 h-100 ${question.answers.every(a => !ImageUtility.isImage(a.imagePath)) ? '' : 'w-100 p-2'}`}
                    id="answer-container-1"
                >
                    {question && (
                        <div
                            className={`row m-0 h-100 ${question.answers.every(a => !ImageUtility.isImage(a.imagePath)) ? 'align-content-center' : 'w-100 p-2'}`}
                            id="answer-container-2"
                        >
                            {question.answers.map((a, i) => (
                                <div
                                    key={i}
                                    className={`answer-container ${ImageUtility.isImage(a.imagePath) ? 'image col-6' : ''} can-click align-content-center`}
                                    onClick={() => { !showAnswer && accessData && submitAnswer(question.id, a.id); }}
                                >
                                    {!ImageUtility.isImage(a.imagePath) && renderAnswerPercentage(i)}

                                    {ImageUtility.isImage(a.imagePath) ? (
                                        <div className="col-12 p-0 answer-image-container">{renderImageChoice(a, i)}</div>
                                    ) : (
                                        <div className={`col-12 p-0 answer-text-container answer-text can-click`}>
                                            {renderChoice(a, i)}
                                            <p className="pl-2 m-0 w-100" style={{ whiteSpace: "break-spaces" }}>
                                                <Latex>{a.value != null ? a.value : ''}</Latex>
                                            </p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}

            {sendingSubmit && (
                <div className="loading-submit-layout">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LiveQuizAnswer;
