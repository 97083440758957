import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestLogin } from '../../stores/authorization/AuthorizationState';
import VideoDuration from '../lesson-list/VideoDuration';
import playListIcon from '../../images/playlist/playlist-icon.svg';
import crownGrey from '../../images/crown/crown-grey.svg';
import crownGold from '../../images/crown/crown-gold.svg';



const ContinueWatchingListItem = (props: {
    lesson: NaTypes.Lesson,
    index: number,
    accessData: NaTypes.Authenticate,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const to = `/video/${props.lesson.chapterList[0].subject}/${props.lesson.chapterList[0].grade}/${props.lesson.chapterList[0].topicId}`;

    const handleItemClick = () => {
        if (!props.accessData.accessToken && props.index !== 0) {
            dispatch(requestLogin());
        } else {
            history.push(to);
        }
    };



    return (
        <div onClick={handleItemClick} className="item col-8 col-sm-6 col-lg-4 col-xl-3 p-0">
            <div className="video-image-container">
                <img className="video-image" src={props.lesson.thumbnail} alt="" />
            </div>
            <div className="video-tag-container">
                <div className="video-tag-status-container">
                    {props.lesson.isCompleted ? (
                        <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGold} alt="" />
                    ) : (
                        <img className="video-tag-status-image" style={{ width: "32px", height: "22px" }} src={crownGrey} alt="" />
                    )}
                    {props.lesson.isFree && <p id="free">Free</p>}
                </div>
                <div className="video-tag-info">
                    <img className="video-tag-playlist-image" src={playListIcon} alt="" />
                    <span>{props.lesson.chapterList.length} คลิป</span>
                </div>
                <div className="video-tag-info">
                    <VideoDuration chapterList={props.lesson.chapterList} />
                </div>
            </div>
            <p className="topic-name">{props.lesson.name}</p>
        </div>
    );
};

export default ContinueWatchingListItem;
