import React from 'react';
import { Link } from 'react-router-dom';
import crownGrey from '../../images/crown/crown-grey.svg';
import crownGold from '../../images/crown/crown-gold.svg';
import playListIcon from '../../images/playlist/playlist-icon.svg';
import VideoDuration from './VideoDuration';
import NaTypes from '../../types';

const LessonListItem = (props: {
    lesson: NaTypes.Lesson,
    index: number,
    accessData: NaTypes.Authenticate,
}) => {
    const { chapterList, name, thumbnail, isCompleted, isFree } = props.lesson;
    const firstChapter = chapterList[0];

    if (!props.accessData.account && props.index > 2) {
        return null;
    }

    return (
        <Link
            to={`/video/${firstChapter.subject}/${firstChapter.grade}/${firstChapter.topicId}`}
            key={props.index}
            className="item-video col-12 col-sm-6 col-lg-4 col-xl-3"
        >
            <div className="video-image-container">
                <img className="video-image" src={thumbnail} alt="" />
            </div>
            <div className="video-tag-container">
                <div className="video-tag-status-container">
                    <img
                        className="video-tag-status-image"
                        style={{ width: "32px", height: "22px" }}
                        src={isCompleted ? crownGold : crownGrey}
                        alt=""
                    />
                    {isFree && <p id="free">Free</p>}
                </div>
                <div className="video-tag-info">
                    <img className="video-tag-playlist-image" src={playListIcon} alt="" />
                    <span>{chapterList.length} คลิป</span>
                </div>
                <div className="video-tag-info">
                    <VideoDuration chapterList={chapterList} />
                </div>
            </div>
            <p className="topic-name">{name}</p>
        </Link>
    );
};

export default LessonListItem;
