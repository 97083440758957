import React from 'react';
import clockIcon from '../../images/clock/clock-icon.svg';



const VideoDuration = ({ chapterList }: { chapterList: NaTypes.ChapterList }) => {

    const totalDuration = chapterList.reduce((total, chapter) =>
        total + chapter.videoList.reduce((sum, video) => sum + video.duration, 0), 0
    );
    if (totalDuration === 0) return null;

    const hours = Math.floor(totalDuration / 3600);
    const minutes = Math.floor((totalDuration % 3600) / 60);

    return (
        <>
            <img src={clockIcon} className="video-duration-image" alt="Clock" />
            <span>{hours > 0 && `${hours} ชั่วโมง `}{minutes} นาที</span>
        </>
    );
};

export default VideoDuration;